import { useState } from "react";

export const useDocumentos = () => {
  const errObj = {};
  const [documentosErr, setErrors] = useState({});

  const objRequeridos = {
    1: 'curriculum',
    2: 'cedula',
    3: 'antecedentes',
    4: 'residencia',
    5: 'afp',
    6: 'salud',
    7: 'visa',
    8: 'discapacidad',
    9: 'covid',
    10: 'jubilacion',
  }

  // const validateDocumentos = obj => {
  //   const { curriculum, cedula, antecedentes, residencia, afp, salud, visa, jubilacion, covid, certificadoCovid, nacionalidad, jubilado } = obj;

  //   if ((!covid || covid.length === 0) && certificadoCovid === "1") {
  //     errObj.covid = "Requerido";
  //   }

  //   if (curriculum === null || curriculum.length === 0) { errObj.curriculum = "Requerido"; }
  //   if (cedula === null || cedula.length === 0) { errObj.cedula = "Requerido"; }
  //   if (antecedentes === null || antecedentes === 0) { errObj.antecedentes = "Requerido"; }
  //   if (residencia === null || residencia === 0) { errObj.residencia = "Requerido"; }
  //   if (afp === null || afp === 0) { errObj.afp = "Requerido"; }
  //   if (salud === null || salud === 0) { errObj.salud = "Requerido"; }
  //   if (parseInt(nacionalidad) !== 1 && !visa) { errObj.visa = "Requerido"; }
  //   if (!jubilacion && jubilado === "1") { errObj.jubilacion = "Requerido"; }

  //   setErrors(errObj);
  // }


  const validateDocumentos = obj => {
    const { curriculum, cedula, antecedentes, residencia, afp, salud, visa, jubilacion, covid, certificadoCovid, nacionalidad, jubilado } = obj;

    if ((!covid) && certificadoCovid === "1") { errObj.covid = "Requerido"; }

    if (!curriculum) { errObj.curriculum = "Requerido"; }
    if (!cedula) { errObj.cedula = "Requerido"; }
    if (!antecedentes) { errObj.antecedentes = "Requerido"; }
    if (!residencia) { errObj.residencia = "Requerido"; }
    if (!afp) { errObj.afp = "Requerido"; }
    if (!salud) { errObj.salud = "Requerido"; }
    if (parseInt(nacionalidad) !== 1 && !visa) { errObj.visa = "Requerido"; }
    if (!jubilacion && jubilado === "1") { errObj.jubilacion = "Requerido"; }

    setErrors(errObj);
  }







  const objDocumentos = obj => {
    const arrRequeridos = obj.map((el) => {
      const llvar = objRequeridos[el.id_tipo_documento];
      return ({ [llvar]: el.url });
    });
    const objFinal = arrRequeridos.reduce((acc, curr) => {
      const [key, value] = Object.entries(curr)[0];
      acc[key] = value;
      return acc;
    }, {});
    return (objFinal);
    // return ({
    //   curriculum: null,
    //   cedula: null,
    //   antecedentes: null,
    //   residencia: null,
    //   afp: null,
    //   salud: null,
    //   visa: null,
    //   discapacidad: null,
    //   covid: null,
    //   jubilacion: null,
    // });
  }

  return {
    documentosErr,
    validateDocumentos,
    objDocumentos,
  }
}