import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// import { useDispatch } from "react-redux";
import { obtenerNivelesEducacionalesAction } from "../../redux/datosEducacionalesDucks";
import { obtenerPaisesAction } from "../../redux/datosZonasDucks";
import FormDatosNacionalidadExtranjera from "./FormDatosNacionalidadExtranjera";
import { objNoSuccess } from "../../utils/Constants";
import { mensajeSimple } from "../ui/sweetAlerts";
import NumberFormat from 'react-number-format';
import ErrorInput from "../ui/ErrorInput";
import { get } from "../../api/apis";

const FormDatosPersonales = ({ formik }) => {
  const [generos, setGeneros] = useState([]);
  const [estadosCiviles, setEstadosCiviles] = useState([]);
  const [paises, setPaises] = useState([]);
  const [nivelesEducacionales, setNiveles] = useState([]);

  const obtenerDatos = async () => {
    let resp = await get('genero');

    if (objNoSuccess[resp]) {
      mensajeSimple('error', objNoSuccess[resp]);
      return;
    }
    setGeneros(resp.data.data);

    resp = await get('estadocivil');
    objNoSuccess[resp] ? mensajeSimple('error', objNoSuccess[resp]) : setEstadosCiviles(resp.data.data);

    resp = await get('pais');
    objNoSuccess[resp] ? mensajeSimple('error', objNoSuccess[resp]) : setPaises(resp.data.data);

    resp = await get('educacion');
    objNoSuccess[resp] ? mensajeSimple('error', objNoSuccess[resp]) : setNiveles(resp.data.data);
  }

  useEffect(() => { obtenerDatos(); }, []);

  useEffect(() => {
    if (formik.values.datosPersonales.nacionalidad === '1') {
      formik.setFieldValue("datosPersonaExtranjera.visaTemporariaDesde", '')
      formik.setFieldValue("datosPersonaExtranjera.visaTemporariaHasta", '')
      formik.setFieldValue("datosPersonaExtranjera.tipoVisa", '');
    }
  }, [formik.values.datosPersonales.nacionalidad]);

  const formatPais = pais => pais.substring(0, 4) !== '0000' ? pais : pais.substring(8, pais.split('').length);

  return (
    <>
      <div className="row mt-3">
        <div className="col-12 col-sm-4 my-1">
          <label htmlFor="nombres">Nombres *</label>
          <input
            type="text"
            maxLength={35}
            name="datosPersonales.nombres"
            className="form-control"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.datosPersonales.nombres}
          />
          {
            formik.errors.datosPersonales !== undefined &&
            formik.touched.datosPersonales !== undefined && (
              <ErrorInput
                touched={formik.touched.datosPersonales.nombres}
                error={formik.errors.datosPersonales.nombres}
              />
            )
          }

        </div>
        <div className="col-12 col-sm-4 my-1">
          <label htmlFor="apellidoPaterno">Apellido Paterno *</label>
          <input
            type="text"
            maxLength={20}
            name="datosPersonales.apellidoPaterno"
            className="form-control"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.datosPersonales.apellidoPaterno}
          />
          {
            formik.errors.datosPersonales !== undefined &&
            formik.touched.datosPersonales !== undefined && (
              <ErrorInput
                touched={formik.touched.datosPersonales.apellidoPaterno}
                error={formik.errors.datosPersonales.apellidoPaterno}
              />
            )
          }
        </div>
        <div className="col-12 col-sm-4 my-1">
          <label htmlFor="apellidoMaterno">Apellido Materno</label>
          <input
            type="text"
            maxLength={20}
            name="datosPersonales.apellidoMaterno"
            className="form-control"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.datosPersonales.apellidoMaterno}
          />
          {
            formik.errors.datosPersonales !== undefined &&
            formik.touched.datosPersonales !== undefined && (
              <ErrorInput
                touched={formik.touched.datosPersonales.apellidoMaterno}
                error={formik.errors.datosPersonales.apellidoMaterno}
              />
            )
          }
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12 col-sm-4 my-1">
          <label htmlFor="fechaNacimiento">Fecha de nacimiento *</label>
          <input
            type="date"
            max="9999-12-31"
            name="datosPersonales.fechaNacimiento"
            className="form-control"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.datosPersonales.fechaNacimiento}
          />
          {
            formik.errors.datosPersonales !== undefined &&
            formik.touched.datosPersonales !== undefined && (
              <ErrorInput
                touched={formik.touched.datosPersonales.fechaNacimiento}
                error={formik.errors.datosPersonales.fechaNacimiento}
              />
            )
          }
        </div>
        <div className="col-12 col-sm-4 my-1">
          <label htmlFor="sexo">Género *</label>
          <select
            className="form-select"
            name="datosPersonales.sexo"
            aria-label="Default select example"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.datosPersonales.sexo}
          >
            <option value="">Seleccione</option>
            {generos &&
              generos.map((genero) => (
                <option key={genero.id} value={genero.id}>
                  {genero.nombre}
                </option>
              ))}
          </select>
          {
            formik.errors.datosPersonales !== undefined &&
            formik.touched.datosPersonales !== undefined && (
              <ErrorInput
                touched={formik.touched.datosPersonales.sexo}
                error={formik.errors.datosPersonales.sexo}
              />
            )
          }
        </div>
        <div className="col-12 col-sm-4 my-1">
          <label htmlFor="estadoCivil">Estado civil *</label>
          <select
            className="form-select"
            name="datosPersonales.estadoCivil"
            aria-label="Default select example"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.datosPersonales.estadoCivil}
          >
            <option value="">Seleccione</option>
            {estadosCiviles &&
              estadosCiviles.map((estadoCivil) => (
                <option key={estadoCivil.id} value={estadoCivil.id}>
                  {estadoCivil.nombre}
                </option>
              ))}
          </select>
          {
            formik.errors.datosPersonales !== undefined &&
            formik.touched.datosPersonales !== undefined && (
              <ErrorInput
                touched={formik.touched.datosPersonales.estadoCivil}
                error={formik.errors.datosPersonales.estadoCivil}
              />
            )
          }
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12 col-sm-4 my-1">
          <label htmlFor="nivelFormacion">Nivel de formación</label>
          <select
            className="form-select"
            name="datosPersonales.nivelFormacion"
            aria-label="Default select example"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.datosPersonales.nivelFormacion}
          >
            <option value="">Seleccione</option>
            {nivelesEducacionales &&
              nivelesEducacionales.map((nivel) => (
                <option key={nivel.id} value={nivel.id}>
                  {nivel.nombre}
                </option>
              ))}
          </select>
        </div>
        <div className="col-12 col-sm-4 my-1">
          <label htmlFor="nacionalidad">Nacionalidad *</label>
          <select
            className="form-select"
            name="datosPersonales.nacionalidad"
            aria-label="Default select example"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.datosPersonales.nacionalidad}
          >
            <option value="">Seleccione</option>
            {paises &&
              paises.map((pais) => (
                <option key={pais.id} value={pais.id}>{formatPais(pais.nombre)}</option>
              ))}
          </select>
          {
            formik.errors.datosPersonales !== undefined &&
            formik.touched.datosPersonales !== undefined && (
              <ErrorInput
                touched={formik.touched.datosPersonales.nacionalidad}
                error={formik.errors.datosPersonales.nacionalidad}
              />
            )
          }
        </div>
        <div className="col-12 col-sm-4 my-1">
          <label htmlFor="email">Email *</label>
          <input
            autoComplete="nope"
            type="email"
            name="datosPersonales.email"
            className="form-control"
            onChange={(e) => {
              formik.setFieldValue("datosPersonales.email", e.target.value.toLowerCase())
              // formik.values.datosPersonales.email = e.target.value.toLowerCase();
              // formik.setValues(formik.values);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.datosPersonales.email}
          />
          {
            formik.errors.datosPersonales !== undefined &&
            formik.touched.datosPersonales !== undefined && (
              <ErrorInput
                touched={formik.touched.datosPersonales.email}
                error={formik.errors.datosPersonales.email}
              />
            )
          }
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12 col-sm-4 my-1">
          <label htmlFor="telefonoCelular">Teléfono celular *</label>
          <div className="input-group">
            <span className="input-group-text" id="inputGroup-sizing-default"> +569 </span>
            <NumberFormat
              name="datosPersonales.telefonoCelular"
              value={formik.values.datosPersonales.telefonoCelular}
              onValueChange={(values) => formik.setFieldValue('datosPersonales.telefonoCelular', values.value.replace(' ', ''))}
              style={{ paddingLeft: '10px' }} decimalSeparator={false}
              format="#### ####" allowNegative={false} className="form-control"
              onBlur={formik.handleBlur}
            />
          </div>
          {
            formik.errors.datosPersonales !== undefined &&
            formik.touched.datosPersonales !== undefined && (
              <ErrorInput
                touched={formik.touched.datosPersonales.telefonoCelular}
                error={formik.errors.datosPersonales.telefonoCelular}
              />
            )
          }
        </div>
      </div>
      {(formik.values.datosPersonales.nacionalidad?.toString() !== "1" && formik.values.datosPersonales.nacionalidad?.toString() !== "") &&
        <FormDatosNacionalidadExtranjera
          formik={formik}
          txtTipoVisa={formik.values.datosPersonaExtranjera.tipoVisa === '2' ? 'Temporaria' : 'Definitiva'}
        />
      }
    </>
  );
};

export default FormDatosPersonales;
