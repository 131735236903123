import { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

import { useDatosPersonalesExtranjero } from "./procesoContratacion/useDatosPersonalesExtranjero";
import { useDatosLaborales } from "./procesoContratacion/datosLaborales/useDatosLaborales";
import { mensajeSimple, modalCargando, modalEjecutar } from "./ui/sweetAlerts";
import { useDatosPersonales } from "./procesoContratacion/useDatosPersonales";
import { BACKDROP, MENSAJE_PROCEDER_CONTRATACION } from "../utils/Constants";
import { useDatosDireccion } from "./procesoContratacion/useDatosDireccion";
import { useDatosBancarios } from "./procesoContratacion/useDatosBancarios";
import { useDocumentos } from "./procesoContratacion/useDocumentos";
import { usePrevision } from "../hooks/datosPrevisionales";
import { DataContext } from "../contextData/contextData";
import { post } from "../api/apis";

export const useContratacionScreen = () => {
  const { page, setearPagina } = useContext(DataContext);
  const { datosExtranjeroErr, validatePersonalesExtranjero } = useDatosPersonalesExtranjero();
  const { datosPersonalesErr, validatePersonales } = useDatosPersonales();
  const { datosDireccionErr, validateDireccion } = useDatosDireccion();
  const { datosLaboralesErr, validateLaborales } = useDatosLaborales();
  const { datosBancariosErr, validateBancarios } = useDatosBancarios();
  const { datosPrevisionErr, validatePrevision } = usePrevision();
  const { documentosErr, validateDocumentos } = useDocumentos();

  const params = useParams();
  const [bloqueado, setBloqueado] = useState(false);

  const returnPorcentaje = page => { return { width: `${{ 0: '0', 1: '20', 2: '40', 3: '60', 4: '80', }[page] || '100'}%` } }


  const validate = (values) => {
    let errors = {
      datosLaborales: datosLaboralesErr,
      datosPersonales: datosPersonalesErr,
      datosPersonaExtranjera: datosExtranjeroErr,
      datosDireccion: datosDireccionErr,
      datosPrevision: datosPrevisionErr,
      datosBancarios: datosBancariosErr,
      datosDocumentos: documentosErr,
    };

    const { nacionalidad } = values.datosPersonales;
    // const { tipoVisa } = values.datosPersonaExtranjera;
    const { jubilado } = values.datosPrevision;
    const { certificadoCovid } = values.datosPrevision;

    validateLaborales(values.datosLaborales, params.idSolicitud);
    validatePersonales(values.datosPersonales);
    validatePersonalesExtranjero({ ...values.datosPersonaExtranjera, nacionalidad });
    validateDireccion(values.datosDireccion);
    validatePrevision(values.datosPrevision);
    validateBancarios(values.datosBancarios);
    validateDocumentos({ ...values.datosDocumentos, certificadoCovid, nacionalidad, jubilado })

    const noHayErrores = Object.values(errors).every(el => Object.values(el).length === 0);
    return noHayErrores ? {} : errors;
  }

  const avanzar = async (props) => {
    //#region BLOQUE DE PRUEBA (debe estar comentado para pruebas)
    // localStorage.setItem('page', parseInt(page) + 1);
    // setearPagina((currentPage) => currentPage + 1);
    // setBloqueado(true);
    // return;
    //#endregion

    modalCargando('Bloqueando posición...');
    const { fechaIngreso, cargo } = props;
    const resp = await post('bloqueoposicion', { codPosicion: JSON.parse(cargo).MOES_COD_POSICION, fechaInicio: fechaIngreso });
    const { status, message } = resp.data;

    if (status === 'info') { mensajeSimple('info', message, 4500); }
    else if (status === 'success') {
      mensajeSimple(status, message, 5000);
      setTimeout(() => {
        localStorage.setItem('page', parseInt(page) + 1);
        setearPagina((currentPage) => currentPage + 1);
        setBloqueado(true);
      }, 5000);
    }
    else { mensajeSimple('error', 'Ha ocurrido un error.'); }
  }

  const siguenteVista = (page, vistaFinal, formik) => {
    if (page === vistaFinal) {
      Swal.fire({
        title: "¿Deseas finalizar el proceso?",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Finalizar",
        reverseButtons: false, // orden botones al reves
        customClass: {
          container: 'containerMensajeSimple py-3',
          text: 'txtLabellInput',
          popup: `popUpSweetCancelarEnviar`,
          confirmButton: 'btnCopec me-4',
          cancelButton: 'btnAtras',
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        backdrop: BACKDROP,
      }).then((result) => {
        if (result.isConfirmed) { formik.handleSubmit(); }
        else { Swal.close(); }
      });
    } else {
      if (page === 0) {
        modalEjecutar(() => { avanzar(formik.values.datosLaborales); }, MENSAJE_PROCEDER_CONTRATACION, null);
      }
      else {
        localStorage.setItem('page', parseInt(page) + 1);
        setearPagina((currentPage) => currentPage + 1);
      }
    }
  }

  const verHabilitado = (page, errors) => {
    let deshabilitado = true;
    const { datosLaborales, datosPersonales, datosPersonaExtranjera, datosDireccion, datosPrevision, datosBancarios, datosDocumentos } = errors;

    const habilitar = obj => (!obj || Object.keys(obj).length === 0) ? false : true;

    switch (page) {
      case 0:
        deshabilitado = habilitar(datosLaborales);
        break;
      case 1:
        deshabilitado = habilitar({ ...datosPersonales, ...datosPersonaExtranjera });
        break;
      case 2:
        deshabilitado = habilitar(datosDireccion);
        break;
      case 3:
        deshabilitado = habilitar(datosPrevision);
        break;
      case 4:
        deshabilitado = habilitar(datosBancarios);
        break;
      case 5:
        deshabilitado = habilitar(datosDocumentos);
        break;
      default:
        deshabilitado = true;
        break;
    }
    return deshabilitado;
  }

  return {
    validate,
    avanzar,
    bloqueado,
    page, setearPagina,
    verHabilitado,
    siguenteVista,
    returnPorcentaje
  }
}