import { BASE_URL_PRODUCCION } from "../../utils/Constants";

export const guardarDatosPersonaExtranjeraApi = async (data, idPersona, metodo = "POST") => {
  try {
    const url = `${BASE_URL_PRODUCCION}/personaextranjera`
    const { tipoVisa, visaTemporariaDesde, visaTemporariaHasta, idPersonaExtranjera } = data;
    const params = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      method: metodo,
      body: JSON.stringify({
        fecha_visa_temporaria: visaTemporariaDesde,
        fecha_vencimiento_visa: visaTemporariaHasta,
        id_persona_tipo_visa: tipoVisa,
        id_persona: idPersona,
        idPersonaExtranjera: idPersonaExtranjera
      })
    };
    const res = await fetch(url, params);
    const resJSON = await res.json()
    return resJSON

  } catch (error) {
    console.log("Error: ", error)
  }
}

export const obtenerPersonaExtranjeraApi = async (idPersonaExtranjera) => {
  try {
    const params = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    }
    const url = `${BASE_URL_PRODUCCION}/personaextranjera?idPersona=${idPersonaExtranjera}`
    const res = await fetch(url, params)
    const resJSON = await res.json()
    return resJSON
  } catch (error) {
    console.log("Error: ", error)
  }
}

export const actualizarPersonaExtranjeraApi = async (idPersonaExtranjera, data) => {
  try {
    const url = `${BASE_URL_PRODUCCION}/personaextranjera?idPersonaExtranjera=${idPersonaExtranjera}`
    const { tipoVisa, visaTemporariaDesde, visaTemporariaHasta } = data;
    const params = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({
        fecha_visa_temporaria: visaTemporariaDesde,
        fecha_vencimiento_visa: visaTemporariaHasta,
        id_persona_tipo_visa: tipoVisa,
        id_persona: idPersonaExtranjera
      })
    }

    const res = await fetch(url, params);
    const resJSON = await res.json()

    return resJSON.data

  } catch (error) {
    console.log("Error: ", error)
  }
}