import { useEffect, useState } from 'react';
import { mensajeSimple, modalCargando, modalEjecutar, ModalEnviandoSAP, modalShowSAP } from './ui/sweetAlerts';
import { customStyles, objStyleCol, txtUnProblema } from '../utils/Constants';
import { BtnDescargarExcel } from './BtnDescargarExcel';
import DataTable from 'react-data-table-component';
import { momentFecha } from "../utils/funciones";
import { getUno, post, put } from '../api/apis';
import Spinner from './ui/Spinner';

export const PorEnviarSAP = () => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [contratosArray, setContratosArray] = useState([])
  const [totalRows, setTotalRows] = useState(10)
  const [perPage, setPerPage] = useState(10)
  const [pageTable, setPageTable] = useState(1)
  const [listaenvios, setListaenvios] = useState([])
  const [count, setCount] = useState(0)
  const [lenght, setLenght] = useState(0)
  const [visibleModal, setVisibleModal] = useState(false)

  const columnas = [
    {
      name: 'Nuevo Colaborador',
      sortable: false,
      selector: row => row.nombre,
      wrap: true,
      minWidth: '300px',
    },
    {
      name: 'Rut Colaborador',
      selector: row => row.identificacion,
      width: '130px',
      style: objStyleCol
    },
    {
      name: 'Cargo',
      sortable: false,
      selector: row => row.cargo,
      wrap: true,
      minWidth: '200px'
    },
    {
      name: 'Instalación / Unidad Solicitante',
      sortable: false,
      selector: row => row.instalacion,
      wrap: true,
      minWidth: '200px'
    },
    {
      name: 'Fecha ingreso',
      sortable: false,
      selector: (row) => momentFecha(row.fecha_ingreso),
      width: '100px',
      style: objStyleCol
    },
  ]

  const enviarToSAP = async (obj) => {
    setVisibleModal(true)
    const arrIds = obj.map((el) => el.id.toString());
    setLenght(arrIds.length);

    let arrError = [];
    let contador = 1;
    for (const item of arrIds) {
      setCount(() => setCount(contador++));

      const resp = await post('altamasivacolaborador', {}, { idSolicitud: item });

      if (resp === (400 || 420)) {
        setVisibleModal(false);
        mensajeSimple('error', `No se ha podido dar de alta al colaborador`, 2000);
        break;
      }
      else {
        if (arrIds.length === 1) {
          mensajeSimple(resp.data.status, resp.data.message, 4000);
        }
      }
      setListaenvios(() => { setListaenvios([...arrError, resp]) });
    }
    setVisibleModal(false)
    llamarPagina();
  }

  const reversarContratacion = async (obj) => {
    const arrIds = obj.map((el) => el.id.toString());
    modalCargando(`Enviando...`);

    const resp = await put('reversarsolicitud', {}, {
      idSolicitud: arrIds,
      observaciones: 'Reversado por Procesos & Remuneraciones',
      rechazo: true,
      rechazo_temporal: false
    });

    if (resp.data?.status === 'success') {
      llamarPagina();
      mensajeSimple(resp.data.status, resp.data.message, 5000);
      if (resp.data?.solicitudes_no_enviadas.length > 0) {
        setTimeout(() => {
          modalShowSAP(resp.data.solicitudes_no_enviadas);
        }, 5000);
      }
    }
    if (resp.data?.status === 'info') {
      llamarPagina();
      mensajeSimple('info', resp.data.message, 5000);
    }
    if (resp === 400 || resp === 500) {
      mensajeSimple('error', txtUnProblema);
    }
  }

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  const seleccionarContrato = (obj) => {
    setContratosArray(obj.selectedRows);
  }

  const borrarChecks = () => {
    if (!document.getElementsByName('select-all-rows')[0]?.checked) {
      document.getElementsByName('select-all-rows')[0]?.click();
    }
    document.getElementsByName('select-all-rows')[0]?.click();
    setContratosArray([])
  }

  const llamarPagina = async (page, total) => {
    borrarChecks();
    const obj = { page: page, total_page: total }
    const resp = await getUno('solicitudpendientesap', obj, null);
    setData(resp.data?.data ? resp.data.data : [])
    setTotalRows(resp.data.paginacion.total)
    setLoading(false)

    if (resp === 500) { mensajeSimple('error', 'La sesión ha caducado.', 3000) }
    if (resp.data.status === 'info') { mensajeSimple(resp.data.status, resp.data.menssage, 2000) }
  }

  const handlePageChange = (page) => {
    setPageTable(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    llamarPagina(page);
  };


  useEffect(() => { llamarPagina(pageTable, perPage); }, [pageTable, perPage]);
  // useEffect(() => { llamarPagina(1, 10); }, []);

  const acondicionarDatos = async () => {
    const contratos = contratosArray.map((el) => el.id.toString());
    const resp = await post('exporttoexcel', null, { idSolicitud: contratos });
    const nombreArchivo = `respaldoSAP_${momentFecha(new Date())}`;
    const columNombresTabla = Object.keys(resp.data.data[0]).filter((el) => el !== 'ID_TRANSACCION');
    const datosTabla = resp.data.data;
    const columMuestreo = Object.keys(resp.data.data[0]).filter((el) => el !== 'ID_TRANSACCION');
    const anchoColumnas = columMuestreo.map((el, indx) => {
      if (el === 'STRAS' || el === 'EMAIL1' || el === 'ORT01' || el === 'VORNA') { return 15 }
      else if (el === 'TELNR' || el === 'GBDAT' || el === 'BANKN') { return 10 }
      else { return 5 }
    })

    return {
      nombreArchivo,
      columNombresTabla,
      datosTabla,
      columMuestreo,
      anchoColumnas
    }
  }



  const cambiarEstadoContratacionEnviadoSap = async (obj) => {
    const arrIds = obj.map((el) => el.id.toString());
    const resp = await put('cambiarEstadoContratacionEnviadoSap', {}, { idSolicitudes: arrIds });
    if (resp.data?.status === 'success') {
      llamarPagina();
      mensajeSimple(resp.data.status, resp.data.message, 5000);
    }
    if (resp.data?.status === 'info') {
      llamarPagina();
      mensajeSimple('info', resp.data.message, 5000);
    }
    if (resp === 400 || resp === 500) {
      mensajeSimple('error', txtUnProblema);
    }
  }



  return (
    <>
      <ModalEnviandoSAP actual={count} max={lenght} visibleModal={visibleModal} />
      <div className="row">
        <div className="col-sm-12 ">
          <div className="card">
            <div className="card-header bgArcopime h5">
              Solicitudes pendientes de envío
            </div>
            {
              loading
                ? (<Spinner />)
                : (
                  <div className="card-body py-0 mt-3">
                    {data.length > 0
                      ?
                      (
                        <DataTable
                          title={false}
                          responsive={true}
                          columns={columnas}
                          data={data}
                          progressPending={loading}
                          pagination
                          paginationServer
                          customStyles={customStyles}
                          paginationDefaultPage={pageTable}
                          paginationPerPage={perPage}
                          paginationTotalRows={totalRows}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                          paginationComponentOptions={paginationComponentOptions}
                          selectableRows
                          onSelectedRowsChange={seleccionarContrato}
                          dense
                          fixedHeader
                          fixedHeaderScrollHeight="450px"
                        />
                      )
                      :
                      <p className='h5 text-center my-3 titleMensajeSimple'>No se encontraron registros</p>
                    }
                  </div>
                )
            }
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-sm-12">
          {contratosArray.length > 0 &&
            <div className='d-flex justify-content-between'>
              <div className='d-flex'>
                <button title="Envía a SAP los registros seleccionados"
                    onClick={() => modalEjecutar(enviarToSAP, 'Se enviará a SAP, ¿Desea continuar?', contratosArray)}
                    className="ms-0 pe-2 btnCopec"
                  >Enviar</button>
                <button title="Cambia el estado de la solicitud a 'Enviado a SAP'"
                  onClick={() => modalEjecutar(cambiarEstadoContratacionEnviadoSap, 'Se cambiarán los estados de contratación de los registros seleccionados. ¿Desea continuar?', contratosArray)}
                  className="btnCopec ms-2" style={{ lineHeight: '17px' }}
                >Enviado a SAP </button>
                <button
                  onClick={() => modalEjecutar(reversarContratacion, 'Se reversará solicitud de contratación. ¿Desea continuar?', contratosArray)}
                  className="btnAtras ms-4" style={{ lineHeight: '17px' }}
                >Reversar contratación</button>
              </div>
              <div>
                <BtnDescargarExcel ejecutarFn={acondicionarDatos} />
              </div>
            </div>
          }
        </div>
      </div>
    </>
  )
}