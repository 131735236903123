import { BASE_URL_PRODUCCION } from "../../utils/Constants";

export const obtenerTipoDocumentoApi = async () => {
    try {
        const params = {
            headers:{
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        } 
        const url = `${BASE_URL_PRODUCCION}/listado/documentotipo`
        const res = await fetch(url,params)
        const resJSON = await res.json()
        return resJSON
    } catch (error) {
        console.log("Error: ", error)
    }
}

export const obtenerDocumentoApi = async (idPersona) => {
    try {
        const params = {
            headers:{
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        } 
        const url = `${BASE_URL_PRODUCCION}/documento?idPersona=${idPersona}`
        const res = await fetch(url,params)
        const resJSON = await res.json()
        return resJSON

    } catch (error) {
        console.log("Error: ", error)
    }
}

export const guardarDocumentoApi = async (documento, idPersona, idTipoDocumento, metodo = "POST") => {
    try {

        const url = `${BASE_URL_PRODUCCION}/documento`

        const formData = new FormData()
        formData.append("documento", documento[0],documento[0].name);
        formData.append("id_persona", idPersona);
        formData.append("id_tipo_documento", idTipoDocumento)

        const params = {
            method: metodo,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: formData
        }

        const res = await fetch(url, params)
        const resJSON = await res.json()
        return resJSON

    } catch (error) {
        console.log("Error :", error)
    }
}

export const eliminarDocumentoApi = async (idDocumento, idPersona) => {
    try {
        const url = `${BASE_URL_PRODUCCION}/documento?idDocumento=${idDocumento}&idPersona=${idPersona}`

        const params = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            method: 'DELETE',
        }

        const res = await fetch(url, params)
        const resJSON = await res.json()
        return resJSON
    } catch (error) {
        console.log("Error: ", error)
    }
}
