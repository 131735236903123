import { useState, useEffect } from "react";
import { faCheckCircle, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

import { obtenerDatosPersonaSolicitanteAction } from "../../../redux/datosPersonaSolicitanteDucks";
import { obtenerSolicitudesReingreso } from "../../../api/contratacion/solicitudVacanteApi";
import { objStyleCol, customStyles } from "../../../utils/Constants";
import { mensajeSimple, modalCargando } from "../../ui/sweetAlerts";
import { ButtonsRRHHyJefeZona } from "../ListadoSolicitudReingreso";
import { modalReingresoOpciones } from "../../ui/sweetAlerts";
import { formatRUT, momentFecha } from "../../../utils/funciones";
import { post } from "../../../api/apis";
import Spinner from "../../ui/Spinner";

export const TablaSolicitudesReingreso = () => {
  const navigate = useNavigate()
  const [arrayTabla, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [pageTable, setPageTable] = useState(1);
  const dispatch = useDispatch()

  const aprobarReingreso = async ({ params, data }) => {
    modalCargando("Enviando reingreso...");
    const resp = await post('respreingreso', params, data);
    fetchUsers(pageTable);
    if (resp.data.status === 'success') {
      mensajeSimple('success', 'Solicitud aprobada con éxito');
    }
    // const remove = validarRespPeticion(resp);
    // if (remove) { setData(arrayTabla.filter((el) => params.idSolicitud !== el.id)); }
  }

  const rechazarReingreso = async ({ params, data }) => {
    modalCargando("Rechazando reingreso...");
    const resp = await post('respreingreso', params, data);
    fetchUsers(pageTable);
    if (resp.data.status === 'success') { mensajeSimple('success', 'Solicitud rechazada con éxito'); }
    // const remove = validarRespPeticion(resp);
    // if (remove) { setData(arrayTabla.filter((el) => params.idSolicitud !== el.id)); }
  }

  const ingresarDatosOcupante = async (rut, idSolicitud) => {
    Swal.fire({
      title: 'ingresando ....',
      didOpen: () => { Swal.showLoading() }
    })
    await dispatch(obtenerDatosPersonaSolicitanteAction(rut, idSolicitud));

    Swal.close();
    navigate("/formulario-contratacion");
  }

  const fetchUsers = async (page) => {
    setLoading(true);
    const data = await obtenerSolicitudesReingreso(page, perPage);
    setLoading(false);
    Swal.close();

    if (data.status === 'info') {
      setData([]);
      mensajeSimple(data.status, data.menssage, 2000);
    }
    if (data.status === 'success') {
      const dataTable = data.data.map((vacante, indx) => {
        let button = []
        if (vacante.ingresar_datos) {
          button = [
            <button
              className="btn borderBlanco rounded-2 btn-success btn-sm"
              data-bs-toggle="tooltip" title="Ingresar datos"
              onClick={() => ingresarDatosOcupante(vacante.identificacion, vacante.id)}
            >
              <FontAwesomeIcon icon={faCheckCircle} />
            </button>
          ]
        } else {
          button = [
            <div className='d-flex flex-row' key={indx}>
              <button
                className="btn borderBlanco roundedLeft rounded-2 btn-primary btn-sm"
                data-bs-toggle="tooltip" title="Ver solicitud"
                onClick={() => modalReingresoOpciones(vacante)}
              >
                <FontAwesomeIcon icon={faEye} title='Ver solicitud' />
              </button>
              <ButtonsRRHHyJefeZona
                vacante={vacante}
                aprobarReingreso={aprobarReingreso}
                rechazarReingreso={rechazarReingreso}
              />
            </div>
          ]
        }

        return {
          id: vacante.id,
          nombre: vacante.nombre,
          identificacion: vacante.identificacion,
          fecha_ingreso: vacante.fecha_ingreso,
          estado_actual: vacante.estado_actual,
          acciones: vacante,
          reeo_codigo_sap: vacante.reeo_codigo_sap,
          responsable: vacante.responsable,
          id_solicitud_estado: vacante.id_solicitud_estado,
          observaciones: vacante.observacion,
        }
      })

      // const set = new Set(dataTable.map(JSON.stringify))
      // const arrUnico = Array.from(set).map(JSON.parse);

      // setData(arrUnico);
      setData(dataTable);
      setTotalRows(data.paginacion.total);
      setLoading(false);
    }
  };

  const handlePageChange = page => {
    setPageTable(page)
    fetchUsers(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    fetchUsers(page)
  };

  const columns = [
    {
      name: 'N° Personal SAP',
      selector: row => row.reeo_codigo_sap !== null ? row.reeo_codigo_sap : '---',
      style: objStyleCol,
      width: "110px",
    },
    {
      name: 'Nuevo colaborador',
      sortable: false,
      selector: row => row.nombre,
      wrap: true,
      minWidth: "250px",
    },
    {
      name: 'Rut',
      selector: row => formatRUT(row.identificacion),
      wrap: true,
      style: objStyleCol,
      width: "105px",
    },
    {
      name: 'Fecha Solicitud',
      selector: row => momentFecha(row.fecha_ingreso.split(' ')[0]),
      wrap: true,
      style: objStyleCol,
      width: "90px",
    },
    {
      name: 'Estado actual',
      selector: row => row.id_solicitud_estado === 16 ? `${row.estado_actual} por ${row.responsable}` : row.estado_actual,
      wrap: true,
      minWidth: "300px",
    },
    {
      name: 'Observaciones',
      selector: row => row.observaciones,
      wrap: true,
      minWidth: "200px",
    },
    {
      name: 'Acciones',
      style: objStyleCol,
      width: "120px",
      button: true,
      cell: (row) => {
        return (
          <div className='d-flex w-100'>
            <button
              className="btn btnAccion btn-primary btn-sm"
              data-bs-toggle="tooltip" title="Ver solicitud"
              onClick={() => modalReingresoOpciones(row.acciones)}
            >
              <FontAwesomeIcon icon={faEye} title='Ver solicitud' />
            </button>
            <ButtonsRRHHyJefeZona
              vacante={row.acciones}
              aprobarReingreso={aprobarReingreso}
              rechazarReingreso={rechazarReingreso}
            />
          </div>
        )
      },
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  useEffect(() => {
    fetchUsers(pageTable);
  }, []);

  return (
    <div className="row">
      <div className="col-sm-12 ">
        <div className="card cardPestania">
          {
            loading
              ? (<Spinner />)
              : (
                <div className="card-body contenedorTabla">
                  {arrayTabla.length > 0
                    ?
                    (
                      <DataTable
                        title={false}
                        responsive={true}
                        columns={columns}
                        data={arrayTabla}
                        progressPending={loading}
                        customStyles={customStyles}
                        pagination
                        paginationServer
                        paginationDefaultPage={pageTable}
                        paginationPerPage={perPage}
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        paginationComponentOptions={paginationComponentOptions}
                        dense
                      />
                    )
                    :
                    <p className='h5 text-center my-5 titleMensajeSimple'>No se encontraron registros</p>
                  }
                </div>
              )
          }
        </div>
      </div>
    </div>
  );
};