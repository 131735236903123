import withReactContent from "sweetalert2-react-content";
import { BACKDROP, CUSTOM_CLASS } from "../../utils/Constants";
import Swal from "sweetalert2";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { momentFecha } from "../../utils/funciones";
import Spinner from './Spinner';
import { isString } from "formik";

const modal = withReactContent(Swal)

export const mensajeSimple = (icono, texto, tiempo = 1500) => {
  Swal.fire({
    position: 'center',
    showCloseButton: false,
    timerProgressBar: false,
    icon: icono,
    title: texto,
    allowEscapeKey: false,
    allowOutsideClick: false,
    showConfirmButton: false,
    timer: tiempo,
    toast: false,
    customClass: {
      container: 'containerMensajeSimple',
      title: 'titleMensajeSimple',
      popup: `popUpMensajeSimple ${icono}`,
      timerProgressBar: 'progressBarMensajeSimple mx-3'
    },
    backdrop: BACKDROP,
    timerProgressBar: true,
  })
}

export const mensajeCompleto = (icono, titulo = '', texto = '', tiempo = 2000) => {
  Swal.fire({
    position: 'center',
    showCloseButton: false,
    timerProgressBar: false,
    icon: icono,
    title: titulo,
    text: texto,
    allowEscapeKey: false,
    allowOutsideClick: false,
    showConfirmButton: false,
    timer: tiempo,
    toast: false,
    customClass: {
      container: 'containerMensajeSimple',
      title: 'titleMensajeSimple',
      popup: `popUpMensajeSimple ${icono}`,
      timerProgressBar: 'progressBarMensajeSimple mx-3'
    },
    backdrop: BACKDROP,
    timerProgressBar: true,
  })
}

export const modalComentario = (ejecutar, txtTitulo, data) => {
  Swal.fire({
    icon: false,
    position: 'center',
    input: 'textarea',
    title: txtTitulo,
    showCancelButton: true,
    cancelButtonText: 'Cancelar',
    confirmButtonText: 'Aceptar',
    allowEscapeKey: false,
    reverseButtons: false, // orden botones al reves
    customClass: CUSTOM_CLASS,
    backdrop: BACKDROP,
    buttonsStyling: false
  }).then(async (result) => {
    if (result.isConfirmed) {
      // if(result.value){
      ejecutar(data, false, false, result.value);
      // }
    }
  });
}

export const modalEjecutar = (ejecutar, txtTitulo, data) => {
  Swal.fire({
    icon: 'info',
    position: 'center',
    html: txtTitulo,
    showCancelButton: true,
    cancelButtonText: 'Cancelar',
    confirmButtonText: 'Aceptar',
    allowEscapeKey: false,
    reverseButtons: false, // orden botones al reves
    customClass: {
      container: 'containerMensajeSimple',
      title: 'titleMensajeSimple',
      text: 'txtLabellInput',
      popup: `popUpSweetCancelarEnviar`,
      confirmButton: 'btnCopec me-4',
      cancelButton: 'btnAtras',
    },
    backdrop: BACKDROP,
    buttonsStyling: false
  }).then(async (result) => {
    if (result.isConfirmed) {
      ejecutar(data);
    }
  });
}

export const modalDeclinarProceso = (ejecutar) => {
  const swalWithBootstrapButtons = Swal.mixin({});

  swalWithBootstrapButtons.fire({
    html: `
      ¿Está seguro que desea declinar el proceso?\n Se perderán los archivos cargados.`,
    icon: 'info',
    showCancelButton: true,
    cancelButtonText: 'Cancelar',
    confirmButtonText: 'Aceptar',
    allowEscapeKey: false,
    reverseButtons: true, // orden botones al reves
    customClass: {
      container: 'containerMensajeSimple',
      title: 'titleMensajeSimple',
      text: 'txtLabellInput',
      popup: `popUpSweetCancelarEnviar`,
      confirmButton: 'btnCopec',
      cancelButton: 'btnAtras',
    },
    backdrop: BACKDROP,
    buttonsStyling: false
  }).then(async (result) => {
    if (result.isConfirmed) {
      ejecutar();
    }
  });
}

export const modalDeclinarSolicitud = (ejecutar, codPosicion, txtMsj) => {
  Swal.fire({
    icon: 'warning',
    position: 'center',
    html: txtMsj,
    showCancelButton: true,
    cancelButtonText: 'Cancelar',
    allowEscapeKey: false,
    confirmButtonText: 'Declinar',
    reverseButtons: false, // orden botones al reves
    customClass: {
      container: 'containerMensajeSimple',
      title: 'titleMensajeSimple',
      text: 'txtLabellInput',
      popup: `popUpSweetCancelarEnviar`,
      confirmButton: 'btnCopec me-4',
      cancelButton: 'btnAtras',
    },
    backdrop: BACKDROP,
    buttonsStyling: false
  }).then(async (result) => {
    if (result.isConfirmed) {
      modalCargando('Liberando posición...');
      ejecutar(codPosicion);
    }
  });
}

export const modalInfoCargoDeclinar = (ejecutar, msjInfo, codPosicion) => {
  Swal.fire({
    icon: 'info',
    position: 'center',
    html: `${msjInfo}`,
    showCancelButton: true,
    allowEscapeKey: false,
    cancelButtonText: 'Cambiar Cargo',
    confirmButtonText: 'Declinar',
    reverseButtons: true, // orden botones al reves
    customClass: {
      container: 'containerMensajeSimple',
      title: 'titleMensajeSimple',
      text: 'txtLabellInput',
      popup: `popUpSweetCancelarEnviar`,
      confirmButton: 'btnCopec',
      cancelButton: 'btnAtras',
    },
    backdrop: BACKDROP,
    buttonsStyling: false
  }).then(async (result) => {
    if (result.isConfirmed) { modalDeclinarSolicitud(ejecutar, codPosicion); }
  });
}

const HtmlTitle = ({ texto }) => <h3>{texto}</h3>

const DataMiniTabla = ({ obj }) => {
  const keys = Object.keys(obj);

  return (
    <div class="accordion-body py-2">
      {keys.length > 0
        ? <>{keys.map(el => isString(obj[el]) ? <div>{el}: {obj[el]}</div> : null)}</>
        : <div>Sin datos disponibles</div>
      }
    </div>
  )
}

export const HtmlList = ({ lista }) => {
  return (
    <>
      <label className="h3 text-center mt-2">Guardando datos</label>
      {
        lista.map((itm, indx) => {
          return (
            <div class="accordion" id="accordionPanelsStayOpenExample" key={indx}>
              <div class="accordion-item mt-2 mx-3">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button py-1" type="button" data-bs-toggle="collapse" data-bs-target={`#panelsStayOpen-collapse${indx}`}
                    aria-expanded="false" aria-controls={`panelsStayOpen-collapse${indx}`}
                  >
                    {itm.texto}
                  </button>
                </h2>
                <div id={`panelsStayOpen-collapse${indx}`} class="accordion-collapse collapse">
                  {itm.data ? <DataMiniTabla obj={itm.data} /> : <p className="text-center py-1 mb-0">No hay detalles para mostrar</p> }
                </div>
              </div>
            </div>
          )
        })
      }
    </>
  )
}

export const HtmlProgresBar = ({ valueNow, valueMax }) => {
  return (
    <>
      <div className="mt-3 d-flex justify-content-center">
        <h4>Enviando {valueNow} de {valueMax}</h4>
      </div>
      <div className="progress mb-4 mx-4">
        <div
          className="progress-bar progressBarMensajeSimple"
          role="progressbar"
          style={{ width: `${parseInt((valueNow / valueMax) * 100)}%` }}
          aria-valuenow={valueNow}
          aria-valuemin={0}
          aria-valuemax={valueMax}
        ></div>
      </div>
    </>
  )
}

const cfgModal = {
  showConfirmButton: false,
  position: 'center',
  // html: <HtmlProgresBar texto={textMsj} />,
  showCancelButton: false,
  allowOutsideClick: false,
  allowEscapeKey: false,
  customClass: {
    container: 'containerMensajeSimple',
    title: 'titleModalCargando',
    text: 'txtLabellInput',
    popup: `popUpSweetCancelarEnviar pt-4 pb-5 h1`,
  },
  backdrop: BACKDROP,
  didOpen: () => { Swal.showLoading() },
}

export const ModalEnviandoSAP = ({ actual, max, visibleModal }) => {
  // modal.fire({...cfgModal, html: <HtmlProgresBar valueNow={actual} valueMax={max} /> })




  return (
    <Modal centered show={visibleModal}>
      <div className="text-center">
        <Spinner />
        <HtmlProgresBar valueNow={actual} valueMax={max} />
      </div>
      {/*
        <button
          className="btnArcoprime mb-4 mt-2"
          onClick={() => {
            setVisibleModal(!visibleModal);
          }}
        >Entendido</button>
      </div> */}
    </Modal>
  )
}

export const ModalEnviandoContratos = ({ actual, max, contratoName, infoContrato, visibleModal }) => {
  // modal.fire({...cfgModal, html: <HtmlProgresBar valueNow={actual} valueMax={max} /> })
  return (
    <Modal centered show={visibleModal}>
      <div className="text-center">
        <Spinner />
        <HtmlProgresBar valueNow={actual} valueMax={max} />
        <div className="text-center">
          <h6>{contratoName}: {infoContrato}</h6>
        </div>
      </div>
      {/*
        <button
          className="btnArcoprime mb-4 mt-2"
          onClick={() => {
            setVisibleModal(!visibleModal);
          }}
        >Entendido</button>
      </div> */}
    </Modal>
  )
}

export const modalCargando = (textMsj = '') => {
  modal.fire({
    showConfirmButton: false,
    position: 'center',
    html: <HtmlTitle texto={textMsj} />,
    showCancelButton: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
    customClass: {
      container: 'containerMensajeSimple',
      title: 'titleModalCargando',
      text: 'txtLabellInput',
      popup: `popUpSweetCancelarEnviar pt-4 pb-5 h1`,
    },
    backdrop: BACKDROP,
    didOpen: () => { Swal.showLoading() },
  });
}

export const modalGuardando = (list = []) => {
  const modal = withReactContent(Swal);
  modal.fire({
    position: 'center',
    showConfirmButton: false,
    // allowOutsideClick: false,
    // allowEscapeKey: false,
    showCancelButton: false,
    html: <HtmlList />,
    customClass: {
      container: 'containerMensajeSimple',
      title: 'titleModalCargando',
      text: 'txtLabellInput',
      popup: `popUpSweetCancelarEnviar pt-4 pb-5 h1`,
    },
    backdrop: BACKDROP,
    didOpen: () => { Swal.showLoading() },
  });
}



export const HtmlListHistorial = ({ lista = [] }) => {
  return (
    <>
      {
        lista.map((el, indx) => {
          return (
            <div className="cardContrato text-start mb-2 py-1 px-2" key={indx}>
              <p className="my-0"><b className="me-1">Responsable:</b> {el.responsable}</p>
              <p className="my-0"><b className="me-1">Estado:</b> {el.nombre_estado}</p>
              <p className="my-0"><b className="me-1">Observación:</b> {el.observaciones}</p>
              <p className="my-0"><b className="me-1">Fecha:</b> {momentFecha(el.fecha.split(' ')[0])}</p>
            </div>
          )
        })
      }
    </>
  )
}
export const modalHistorial = (titulo = 'serysryreye', list = []) => {
  const modal = withReactContent(Swal);
  modal.fire({
    position: 'center',
    showConfirmButton: false,
    title: titulo,
    showConfirmButton: true,
    // allowOutsideClick: false,
    // allowEscapeKey: false,
    showCancelButton: false,
    confirmButtonText: 'Entendido',
    html: <HtmlListHistorial lista={list} />,
    customClass: {
      container: 'containerMensajeSimple',
      title: 'titleModalCargando',
      text: 'txtLabellInput',
      popup: `popUpSweetCancelarEnviar pt-2 pb-5 h1`,
      confirmButton: 'btnSweetSolo',
    },
    buttonsStyling: false,
    backdrop: BACKDROP,
    // didOpen: () => {Swal.showLoading()},
  });
}

export const modalShowSAP = (obj) => {
  const modal = withReactContent(Swal);
  modal.fire({
    position: 'top',
    showConfirmButton: false,
    title: "Solicitudes no enviadas",
    allowOutsideClick: false,
    allowEscapeKey: false,
    showCancelButton: false,
    showCancelButton: true,
    buttonsStyling: false,
    cancelButtonText: 'Entendido',
    html:
      <>
        {obj.map((el, indx) => {
          return (
            <div className="accordion" id={`accordionExample${indx}`}>
              <div className="accordion-item">
                <h2 className="accordion-header" id={`headingOne${indx}`}>
                  <button className="accordion-button h2 mb-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseOne${indx}`} aria-expanded="false" aria-controls={`collapseOne${indx}`}>
                    Nro - {el.idSolicitud}
                  </button>
                </h2>
                <div id={`collapseOne${indx}`} className="accordion-collapse collapse" aria-labelledby={`headingOne${indx}`} data-bs-parent={`accordionExample${indx}`}>
                  <div className="accordion-body p-1">
                    <pre>
                      <code><b className="me-1" style={{ minWidth: 'fit-content' }}>Repuesta SAP:</b>{el['respuesta SAP']}</code>
                      <hr className="m-1" />
                      <code>
                        {JSON.stringify(el.solicitud, null, 2)}
                      </code>
                    </pre>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </>,
    customClass: {
      container: 'containerMensajeSimple',
      title: 'titleModalCargando pt-0',
      text: 'txtLabellInput',
      popup: `popUpSweetCancelarEnviar pt-4 pb-3 h1`,
      cancelButton: 'btnAtras'
    },
    backdrop: BACKDROP,
    // didOpen: () => {Swal.showLoading()},
  });
}

const DatosSolicitante = ({ props }) => {
  const nombres = props.nombre.split(' ');
  const nombre = nombres.slice(0, -2).join(' ');
  const apellidoPaterno = nombres.slice(-2, -1).join(' ');
  const apellidoMaterno = nombres.slice(-1).join(' ') || '---';
  return (
    <>
      <h3 className="mb-4">Detalles solicitud</h3>
      <p><b className="me-1">Nombre:</b>{nombre}</p>
      <p><b className="me-1">Apellido paterno:</b>{apellidoPaterno}</p>
      <p><b className="me-1">Apellido materno:</b>{apellidoMaterno}</p>
      <p><b className="me-1">Rut:</b>{props.identificacion}</p>
      <p><b className="me-1">Motivo de reingreso:</b>{props.motivo_ingreso}</p>
      <p><b className="me-1">Fecha de baja:</b>{props.fecha_baja}</p>
    </>
  )
}

export const modalReingresoOpciones = (solicitante) => {
  const modal = withReactContent(Swal);
  modal.fire({
    position: 'center',
    html: <DatosSolicitante props={solicitante} />,
    confirmButtonText: 'Ok',
    allowEscapeKey: true,
    reverseButtons: true, // orden botones al reves
    customClass: {
      container: 'containerMensajeSimple',
      title: 'titleMensajeSimple',
      text: 'txtLabellInput',
      popup: `popUpSweetCancelarEnviar`,
      confirmButton: 'btnSweetSolo',
      htmlContainer: 'contHtmlModalReingreso'
    },
    backdrop: BACKDROP,
    buttonsStyling: false,
  });
}

export const modalReingresoAccion = async (vacante, ejecutar, titulo, opcionAccion) => {
  Swal.fire({
    input: 'textarea',
    title: titulo,
    inputPlaceholder: `Motivo...`,
    inputAttributes: { 'aria-label': 'Type your message here' },
    reverseButtons: false, // orden botones al reves
    customClass: {
      container: 'containerMensajeSimple',
      title: 'titleMensajeSimple',
      text: 'txtLabellInput',
      popup: `popUpSweetCancelarEnviar`,
      confirmButton: 'btnSweetSolo me-4',
      cancelButton: 'btnAtras',
    },
    buttonsStyling: false,
    confirmButtonText: opcionAccion === 1 ? "Aceptar" : "Rechazar",
    cancelButtonText: `Cancelar`,
    backdrop: BACKDROP,
    showCancelButton: true
  }).then((result) => {
    if (result.isConfirmed) {
      const body = { ...{ params: { idSolicitud: vacante.id } }, ...{ data: { comentario: result.value, resolucion: opcionAccion } } } // 1 aceptado, 2 rechazado
      modalEjecutar(ejecutar, `¿Está seguro de ${opcionAccion === 1 ? 'aceptar' : 'rechazar'} este reingreso?`, body);
      // ejecutar(body);
    } else if (result.isDenied) { Swal.close(); }
  })
}




const HtmlContrato = ({ ejecutar, contratos }) => {
  return (
    <>
      <h3 className="mb-3">Revisión de contrato</h3>
      <div className="contenedorCardsModal">
        {contratos.map((el, indx) => {
          return (
            <div className="cardContrato mb-2 py-1 px-2" key={indx}>
              <p className="my-0"><b className="me-1">Nombre:</b> {el.Nombre}</p>
              <p className="my-0"><b className="me-1">División:</b> {el.Division}</p>
              <p className="my-0"><b className="me-1">Cargo:</b> {el.Cargo}</p>
              <p className="my-0"><b className="me-1">Plan de horario:</b> {el.Phtd}</p>
              <p className="my-0"><b className="me-1">Nombre:</b> {el.Nombre}</p>
              <p className="my-0"><b className="me-1">Unidad organizativa:</b> {el.TextoUnidadOrganizativa}</p>
              <p className="my-0"><b className="me-1">Documento:</b> {el.NombrePlantilla}</p>
            </div>
          )
        }
        )}
      </div>
      <div className="d-flex justify-start flex-column mt-3">
        <b htmlFor="inputMotivo" className="text-start">Motivo</b>
      </div>
    </>
  )
}
export const modalAprobarRechazarContrato = (contratos, resolucionContrato) => {
  let motivoModif = (txt) => txt
  // const arrayIdContratos = (lista) => { return lista.map((el) => el.id.toString()); }
  const arrayIdContratos = (lista) => { return lista.map((el) => ({ id: el.id.toString(), nombre: el.Nombre })) }
  const modal = withReactContent(Swal);
  modal.fire({
    input: 'textarea',
    position: 'center',
    showConfirmButton: true,
    showCancelButton: false,
    showDenyButton: true,
    showCloseButton: true,
    allowOutsideClick: true,
    reverseButtons: false,
    allowEscapeKey: true,
    buttonsStyling: false,
    html: <HtmlContrato ejecutar={motivoModif} contratos={contratos} />,
    denyButtonText: 'Rechazar',
    confirmButtonText: 'Aceptar',
    customClass: {
      container: 'containerMensajeSimple',
      title: 'titleMensajeSimple',
      input: 'form-control mt-0 inputMotivo pt-1',
      text: 'txtLabellInput',
      popup: `popUpSweetCancelarEnviar`,
      confirmButton: 'btnCopec me-4 mt-1',
      denyButton: 'btnAtras',
      htmlContainer: 'contHtmlModalReingreso mt-2 mb-0',
      closeButton: 'btnCerrarModal'
    },
    backdrop: BACKDROP,
    // didOpen: () => {Swal.showLoading()},
  }).then((result) => {
    if (result.isConfirmed) {
      modalEjecutar(resolucionContrato, 'Se procederá a aceptar estos documentos. ¿Desea Continuar?', { idContrato: arrayIdContratos(contratos), respuesta: '1', motivo: result.value });
    }
    if (result.isDenied) {
      modalEjecutar(
        resolucionContrato, 'Se procederá a rechazar estos documentos. ¿Desea Continuar? ',
        {
          idContrato: arrayIdContratos(contratos),
          respuesta: '2',
          motivo: document.querySelector('.inputMotivo').value
        }
      );
    }
  });
}

export const ModalVerNotificaciones = ({ visible, lista }) => {
  return (
    <Modal show={visible} lista={lista}>
      <div>vaina</div>
    </Modal>

  )
}