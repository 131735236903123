import ErrorInput from "../ui/ErrorInput";

export const InputSelect = ({ label, subObjeto, arrData, formik }) => {

  return (
    <>
      <label htmlFor="">{label}</label>
      <select
        name={`${subObjeto[0]}.${subObjeto[1]}`}
        className="form-select"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values[subObjeto[0]][subObjeto[1]]}
      >
        <option value="">Seleccione</option>
        {arrData &&
          arrData.map((el, indx) => <option value={el.value} key={indx}>{el.label}</option>)
        }
      </select>
      {formik.errors[subObjeto[0]] !== undefined &&
        formik.touched[subObjeto[0]] !== undefined && (
          <ErrorInput
            touched={formik.touched[subObjeto[0]][subObjeto[1]]}
            error={formik.errors[subObjeto[0]][subObjeto[1]]}
          />
        )}
    </>
  )
}