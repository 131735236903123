import React, { useEffect } from "react";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { modalCargando } from "./ui/sweetAlerts";

const IngresoIntranet = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  const setPerfilUsuario = () => {
    // Decodifica la informacion que mantiene le token
    const data = jwt_decode(token);

    // Si el token mantiene un perfil activo este lo redirecciona automaticamente
    localStorage.setItem('cod_division', data.cod_division)
    localStorage.setItem('foto_perfil', data.photo)
    localStorage.setItem('usuarioNombre', data.nombre)
    localStorage.setItem('perfil', data.nombre_perfil)
    localStorage.setItem('token', token)
    localStorage.setItem('id_perfil', data.id_perfil)
    localStorage.setItem('id_usuario_sap', data.id_usuario_sap)
    localStorage.setItem('user_id', data.id_usuario)
    modalCargando("Ingresando...");
    redireccion();
  }

  const redireccion = () => {
    Swal.close();
    return navigate(`/`);  // Ruta por defecto si ningún caso coincide
  }

  useEffect(() => {
    setPerfilUsuario();
  }, []);

  return (
    <></>
  );
};

export default IngresoIntranet;
