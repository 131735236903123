import { useState } from "react";
import { get } from "../api/apis";

export const usePrevision = () => {
  const [listadoAfp, setListadoAfp] = useState([]);
  const [listadoPrevision, setListadoPrevision] = useState([]);
  const [datosPrevisionErr, setErrors] = useState({});
  let errObj = {};

  const getListaAfp = async (api, params) => {
    if ([undefined].includes(params.jubilado)) { return }
    if (params.jubilado === '') {
      setListadoAfp([]);
    } else {
      const resp = await get(api, params);
      setListadoAfp(resp.data);
    }
  }

  const getListaSalud = async (api, params) => {
    const resp = await get(api, params);
    setListadoPrevision(resp.data.data);
  }

  const validatePrevision = obj => {
    const { jubilado, previsionSalud, previsionSocial, planPactado } = obj;

    if (!jubilado) { errObj.jubilado = "Requerido"; }
    if (!previsionSalud) { errObj.previsionSalud = "Requerido"; }
    if (!previsionSocial) { errObj.previsionSocial = "Requerido"; }
    if (parseInt(previsionSalud) !== 1 && !planPactado) { errObj.planPactado = "Requerido"; }

    const regexp = /^\d+(\.\d{1,4})?$/;

    if (previsionSalud !== "1" && (planPactado === '0' || planPactado === '0,')) {
      errObj.planPactado = "Ingrese corretamente el valor de la UF";
    }
    setErrors(errObj);
  }

  return { getListaAfp, getListaSalud, listadoAfp, listadoPrevision, datosPrevisionErr, validatePrevision }
}