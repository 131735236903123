import { useState } from "react";
import { DOMINIOS_VALIDOS } from "../../utils/Constants";
import { errDigitosAnio } from "../../utils/Constants";
import { returnAnio } from "../../utils/funciones";
import moment from "moment";

export const useDatosPersonales = () => {
  let errObj = {};
  const [datosPersonalesErr, setErrors] = useState(errObj);

  const validaFechaNacimiento = fecha => {
    const fechaHoy = new Date();
    if (moment(fechaHoy).diff(moment(fecha), "years") < 18) {
      errObj.fechaNacimiento = "La persona debe ser mayor de 18 años.";
      return;
    }
    if (returnAnio(fecha) < 1000) {
      errObj.fechaNacimiento = errDigitosAnio;
      return;
    }
    if (moment(fechaHoy).diff(moment(fecha), "years") > 90) {
      errObj.fechaNacimiento = "No puede solicitar ingreso de personal con mas de 90 años, por favor revisar.";
      return;
    }
  }

  const validatePersonales = obj => {
    const { nombres, apellidoPaterno, fechaNacimiento, sexo, nacionalidad, email, telefonoCelular, estadoCivil } = obj;
    
    if (!nombres) { errObj.nombres = "Requerido"; }
    if (!apellidoPaterno) { errObj.apellidoPaterno = "Requerido"; }
    if (!estadoCivil) { errObj.estadoCivil = "Requerido"; }
    if (!fechaNacimiento) { errObj.fechaNacimiento = "Requerido"; }
    if (!sexo) { errObj.sexo = "Requerido"; }
    if (!nacionalidad) { errObj.nacionalidad = "Requerido"; }
    if (!telefonoCelular) { errObj.telefonoCelular = "Requerido"; }

    validaFechaNacimiento(fechaNacimiento);

    if (!email) {
      errObj.email = "Requerido";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      errObj.email = "Direccion de correo invalida.";
    }
    if (DOMINIOS_VALIDOS.includes(email.split("@")[1])) {
      errObj.email = "El dominio del correo es inválido";
    }

    if (telefonoCelular.length > 0 && telefonoCelular.length !== 8) {
      errObj.telefonoCelular = "debe ingresar 8 digitos.";
    }

    setErrors(errObj);
  }

  return { datosPersonalesErr, validatePersonales }
}