import React, { useState, useEffect } from "react";
import { aprobarSolicitudVacanteApi, guardarVacanteApi, guardadoParcialApi } from "../api/contratacion/solicitudVacanteApi";
import { guardarDatosPersonaExtranjeraApi } from "../api/contratacion/datosPersonaExtranjeraApi";
import { modalDeclinarSolicitud, modalDeclinarProceso, mensajeSimple,modalCargando } from "./ui/sweetAlerts";
import { PERSONA_VALORES_INICIALES, txtMensajeDatosIngresados } from "../utils/Constants.js";
import FormDatosLaborales from "./procesoContratacion/datosLaborales/FormDatosLaborales.jsx";
import { guardarDatosPersonalesApi } from "../api/contratacion/datosPersonalesApi";
import { guardarDatosPrevisionApi } from "../api/contratacion/datosPrevisionApi";
import { guardarDatosBancariosApi } from "../api/contratacion/datosBancariosApi";
import { guardarDatosLaboralesApi } from "../api/contratacion/datosLaboralesApi";
import { guardarDireccionApi } from "../api/contratacion/datosDireccionApi";
import FormDatosPersonales from "./procesoContratacion/FormDatosPersonales";
import FormDatosPrevision from "./procesoContratacion/FormDatosPrevision";
import FormDatosBancarios from "./procesoContratacion/FormDatosBancarios";
import FormDatosDireccion from "./procesoContratacion/FormDatosDireccion";
import { guardarDatosSaludApi } from "../api/contratacion/datosSaludApi";
import { guardarDocumentoApi } from "../api/contratacion/documentosApi";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import FormDocumentos from "./procesoContratacion/FormDocumentos";
import { DOCUMENTOS } from "../utils/Constants";
import { HtmlList } from "./ui/sweetAlerts";
import { SpinnerModal } from "./ui/Spinner";
import { getUno, post } from "../api/apis";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { useContratacionScreen } from "./useContratacionScreen.js";

const FormContratacionScreen = () => {
  const [listaGuardado, setListaGuardado] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleSpinner, setVisibleSpinner] = useState(true);
  const [txtSpinner, setTxtSpinner] = useState('');
  const { idPersona } = useParams();
  const navigate = useNavigate();
  const msjCorrecto = ", guardado correctamente."
  const msjIncorrecto = ", no se pudo guardar."

  const FormTitles = [
    "Datos Laborales",
    "Datos Personales",
    "Datos de dirección",
    "Datos de previsión",
    "Datos bancarios",
    "Documentos / Soporte de contratación",
  ];

  const { validate, siguenteVista, bloqueado, page, setearPagina, verHabilitado, returnPorcentaje } = useContratacionScreen();

  function setearMensaje(tipo, resp) {
    setListaGuardado(
      (listaGuardado) => [
        ...listaGuardado,
        {
          icono: `${resp.status === 'success' ? 'check.png' : 'warning.png'}`,
          texto: `${tipo}${resp.status === 'success' ? msjCorrecto : msjIncorrecto}`,
          Tooltip: `${resp.status === 'info' ? resp.message : ''}`,
          data: resp.data ? resp.data : { data: 'Sin especificar' }
        }
      ]
    );
  }

  async function getUnaPersona(id) {
    const respPersona = await getUno('persona', { idPersona: id });
    const {
      apellido_mat,
      apellido_pat,
      correo,
      fecha_nacimiento,
      // id,
      id_educacion_nivel,
      id_persona_estado_civil,
      id_persona_genero,
      identificacion,
      nombre
    } = respPersona.data.data;

    formik.setValues({
      ...formik.values, datosPersonales:
      {
        apellidoMaterno: apellido_mat,
        apellidoPaterno: apellido_pat,
        email: correo,
        estadoCivil: id_persona_estado_civil,
        sexo: id_persona_genero,
        nombres: nombre,
        nivelFormacion: id_educacion_nivel || '',
        rut: identificacion,
        fechaNacimiento: fecha_nacimiento !== 'None' ? fecha_nacimiento : '',
        telefonoCelular: ''
      }
    });
  }

  useEffect(() => {
    if (!localStorage.getItem('rut')) {
      mensajeSimple('info', 'No existe un rut asociado al proceso, será redireccionado', 3500);
      setTimeout(() => {
        navigate('/consulta-rut');
      }, 3500);
    }
    if (idPersona) {
      getUnaPersona(idPersona);
    }
  }, []);

  const saveNewDataFrom2 = async (values, personaSolicitante = false, editaVacante = true) => {
    const valuesPersonales = { ...values.datosPersonales, rut: localStorage.getItem('rut') }
    setVisibleModal(!visibleModal);

    localStorage.removeItem('rut');

    setTxtSpinner(() => setTxtSpinner('Datos personales'));
    let resp = await guardarDatosPersonalesApi(valuesPersonales);
    setearMensaje('Datos personales', resp);

    const idPersona = resp.data.id_persona;

    if (values.datosPersonales.nacionalidad != '1') {
      setTxtSpinner(() => setTxtSpinner('Datos persona extranjera'));
      const respExtranjero = await guardarDatosPersonaExtranjeraApi(values.datosPersonaExtranjera, idPersona);
      setearMensaje('Datos persona extranjera', respExtranjero);
    }
    if (page >= 2) {
      setTxtSpinner(() => setTxtSpinner('Datos dirección'));
      resp = await guardarDireccionApi(values.datosDireccion, idPersona);
      setearMensaje('Datos dirección', resp);
    }
    if (page >= 3) {
      setTxtSpinner(() => setTxtSpinner('Datos previsión'));
      resp = await guardarDatosPrevisionApi(values.datosPrevision, idPersona);
      setearMensaje('Datos previsión', resp);

      setTxtSpinner(() => setTxtSpinner('Datos salud'));
      resp = await guardarDatosSaludApi(values.datosPrevision, idPersona);
      setearMensaje('Datos salud', resp);
    }
    if (page >= 4) {
      setTxtSpinner(() => setTxtSpinner('Datos bancarios'));
      resp = await guardarDatosBancariosApi(values.datosBancarios, idPersona);
      setearMensaje('Datos bancarios', resp);
    }
    if (page === 5) {
      await Promise.all(DOCUMENTOS.map(async (dataDocumento) => {
        if (values.datosDocumentos[dataDocumento.documento] !== null) {
          setTxtSpinner(() => setTxtSpinner('Documento'));
          const respDocumento = await guardarDocumentoApi(values.datosDocumentos[dataDocumento.documento], idPersona, dataDocumento['id_tipo']);
          setearMensaje('Documento', { ...respDocumento, data: dataDocumento.nombre_mensaje });
        }
      }));
    }
    if (personaSolicitante && editaVacante) {
      let ingresarDatosLAborales = false;

      if (!personaSolicitante.idSolicitud) {
        setTxtSpinner(() => setTxtSpinner('Solicitud'));

        if ((page < FormTitles.length - 1) && (page > 0)) {
          var respVacante = await guardadoParcialApi(idPersona, 1, page, values);
        } else {
          var respVacante = await guardarVacanteApi(idPersona, 1, page, values);
        }
        setearMensaje('Solicitud', respVacante);
        if (respVacante.status === 'success') { ingresarDatosLAborales = true; }
      }

      if (ingresarDatosLAborales || personaSolicitante.idSolicitud) {
        const id_solicitud = personaSolicitante.idSolicitud ? personaSolicitante.idSolicitud : respVacante.data.id_solicitud;
        setTxtSpinner(() => setTxtSpinner('Datos laborales'));
        const respDatosLaborales = await guardarDatosLaboralesApi(values.datosLaborales, id_solicitud);
        setearMensaje('Datos laborales', respDatosLaborales);

        if (personaSolicitante.idSolicitud && page === 5) {
          setTxtSpinner(() => setTxtSpinner('Vacante'));
          const respSolicitante = await aprobarSolicitudVacanteApi(id_solicitud);
          setearMensaje('Vacante', respSolicitante);
        }
        if (respDatosLaborales.status === "success") {
          setTimeout(() => {
            localStorage.removeItem('rut');
            window.location = "/";
          }, 300000);
        }
      }
    }
    setVisibleSpinner(!visibleSpinner);
  }

  const PageDisplay = () => {
    if (page === 0) { return <FormDatosLaborales bloqueado={bloqueado} formik={formik} />; }
    if (page === 1) { return <FormDatosPersonales formik={formik} />; }
    if (page === 2) { return <FormDatosDireccion formik={formik} />; }
    if (page === 3) { return <FormDatosPrevision formik={formik} />; }
    if (page === 4) { return <FormDatosBancarios formik={formik} />; }
    if (page === 5) { return <FormDocumentos formik={formik} />; }
  }

  const personaSolicitante = useSelector(store => store.datosPersonaSolicitante.dataPersona);
  const guardarProcesoDeContratacion = (values) => {
    saveNewDataFrom2(values, personaSolicitante);
  }

  const formik = useFormik({
    initialValues: PERSONA_VALORES_INICIALES(personaSolicitante),
    enableReinitialize: true,
    validateOnMount: true,
    validate,
    onSubmit: (values) => { guardarProcesoDeContratacion(values) },
  });

  const disabledButtonNext = () => formik.dirty ? verHabilitado(page, formik.errors) : true;

  const resetValores = (arr) => {
    arr.forEach((el) => formik.setFieldValue(`datosDocumentos.${el}`, null));
  }

  const declinar = () => {
    localStorage.setItem('page', parseInt(page) - 1);
    setearPagina((currentPage) => currentPage - 1);
    resetValores([
      'curriculum',
      'cedula',
      'antecedentes',
      'residencia',
      'afp',
      'salud',
      'visa',
      'discapacidad',
      'covid',
      'jubilacion'
    ]);
  }

  const funcionMostrarModal = () => {
    let mostrar = false;
    (Object.values(formik.values.datosDocumentos)).forEach((el) => {
      if (el !== null) { mostrar = true; }
    });
    return mostrar;
  }


  const Preguardadotemporal = async (formik) => {
    if (!await evaluaCorreoActivo(formik.datosPersonales.email)){
      return
    }
    guardarProcesoDeContratacion(formik)
  }

  const ContinuaProceso = async (page, FormTitles, formik) => {
    if (page != 1 ){
      siguenteVista(page, FormTitles, formik);
      return
    }
    //aca llamar endpoint
    if (!await evaluaCorreoActivo(formik.values.datosPersonales.email)){
      return
    }
    siguenteVista(page, FormTitles, formik);
  }


  const evaluaCorreoActivo = async (email) =>{
    modalCargando('Verificando email...');
    const resp = await post('verificacorreousuarioactivo', "",{ email: email })
    if (resp.data.status === 'info') {
      mensajeSimple('error', resp.data.message, 2500)
      return false
    }
    mensajeSimple('success', "Email permitido", 1500)
    return true
  }

  const evaluarRetorno = () => {
    if (funcionMostrarModal()) { modalDeclinarProceso(declinar); }
    else {
      localStorage.setItem('page', parseInt(page) - 1);
      setearPagina((currentPage) => currentPage - 1);
    }
  }

  const declinarSolicitud = async (codPosicion) => {
    const { fechaIngreso, cargo } = formik.values.datosLaborales;
    const resp = await post('desbloqueoposicion', { codPosicion: JSON.parse(cargo).MOES_COD_POSICION, fechaInicio: fechaIngreso });

    if (resp.data.status === 'info') {
      mensajeSimple('error', 'La posición no pudo ser liberada.', 2500);
    }
    else if (resp.data.status === 'success') {
      mensajeSimple(resp.data.status, resp.data.message, 5000);
      setTimeout(() => {
        localStorage.setItem('page', 0);
        setearPagina(0);
        if (page !== 1) { navigate('/consulta-rut'); }
      }, 5000);
    }
    else { mensajeSimple('error', 'Ha ocurrido un error.'); }
  }

  useEffect(() => { disabledButtonNext(); }, [page]);


  useEffect(() => {
    formik.setErrors(validate(formik.values));
  }, [formik.values]);

  return (
    <>
      <Modal show={visibleModal}>
        <HtmlList lista={listaGuardado} />

        {visibleSpinner && <SpinnerModal txt={txtSpinner} />}

        {!visibleSpinner &&
          <>
            <div className="text-center">
              <button
                className="btnArcoprime mb-4 mt-2"
                onClick={() => {
                  setVisibleSpinner(!visibleSpinner);
                  setVisibleModal(!visibleModal);
                  window.location = "/";
                }}
              >Entendido</button>
            </div>
          </>
        }
      </Modal>
      <div className="form mx-5">
        <div className="form-container mx-5">
          <div className="header">
            <div className="progress my-3">
              <div
                className="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                aria-valuenow="75"
                aria-valuemin="0"
                aria-valuemax="100"
                style={returnPorcentaje(page)}
              ></div>
            </div>
            <div className=" d-flex justify-content-between align-items-baseline">
              <div>
                <h1 className="mb-0"> {FormTitles[page]}{" "} </h1>
                <span className="mt-0" style={{ weight: '700' }}>
                  Campos requeridos poseen este símbolo (*)
                </span>
              </div>

            </div>
          </div>

          <div className="body">{PageDisplay()} </div>
          <div className="footer mb-5">
            <div className="float-end">
              {((page < FormTitles.length - 1) && (page > 0)) &&
                <button className="btn btn-success mt-4 me-2" type="button" disabled={disabledButtonNext()}
                  onClick={() => { Preguardadotemporal(formik.values) }}
                >Guardado temporal</button>
              }
              {(bloqueado && page !== 0) &&
                <button
                  className="btn btn-danger align-items-center mt-4 me-5 candadoPosicion"
                  onClick={() => {
                    const { cargo } = formik.values.datosLaborales;
                    modalDeclinarSolicitud(
                      declinarSolicitud,
                      JSON.parse(cargo).MOES_COD_POSICION,
                      `¿Está seguro de liberar la posición? Se perderá el proceso actual y la posición <b>${JSON.parse(cargo).MOES_COD_POSICION} - ${JSON.parse(cargo).MOES_DESCRIPCION_POSICION}</b> estará disponible nuevamente.`);
                  }}
                >
                  Declinar solicitud
                </button>
              }
              <button
                className="btn btn-outline-primary mt-4 me-2"
                onClick={() => {
                  if (page === 0) { navigate('/consulta-rut') }
                  else if (page === 1 && bloqueado) {
                    const { cargo } = formik.values.datosLaborales;
                    modalDeclinarSolicitud(
                      declinarSolicitud,
                      JSON.parse(cargo).MOES_COD_POSICION,
                      txtMensajeDatosIngresados
                    );
                  }
                  else if (page === 5) { evaluarRetorno(); }
                  else {
                    localStorage.setItem('page', parseInt(page) - 1);
                    setearPagina((currentPage) => currentPage - 1);
                  }
                }}
              >Ir atrás</button>
              <button
                id="btnContinuarContratacion"
                disabled={disabledButtonNext()}
                className={page === FormTitles.length - 1 ? `btn btn-success mt-4 ` : `btn btn-primary mt-4 `}
                onClick={() => { ContinuaProceso(page, FormTitles.length - 1, formik); }}
              >
                {page === FormTitles.length - 1 ? "Enviar a Aprobación" : "Continuar"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormContratacionScreen;