import { defaultThemes } from "react-data-table-component";
export const BASE_URL_PRODUCCION = 'https://arcoprime-contrata-oda-api-dev.i1.cl/api'
//export const BASE_URL_PRODUCCION = 'http://localhost:5000/api';

export const txtMensaje = 'El usuario con el que se ha logeado no mantiene posiciones, no puede continuar con el flujo, será redireccionado.';

export const PLAZOS = [
  { label: "Plazo Fijo 30 Días", value: "1" },
  { label: "Plazo Fijo 60 Días", value: "2" },
  { label: "Plazo Fijo 90 Días", value: "3" },
  { label: "Indefinido", value: "4" }
]

export const DOCUMENTOS = [
  { 'id_tipo': 1, 'documento': "curriculum", "nombre_mensaje": 'Currículum vitae' },
  { 'id_tipo': 2, 'documento': "cedula", "nombre_mensaje": 'Cédula identidad' },
  { 'id_tipo': 3, 'documento': "antecedentes", "nombre_mensaje": 'Certificado antecedentes' },
  { 'id_tipo': 4, 'documento': "residencia", "nombre_mensaje": 'Certificado residencia' },
  { 'id_tipo': 5, 'documento': "afp", "nombre_mensaje": 'Certificado AFP' },
  { 'id_tipo': 6, 'documento': "salud", "nombre_mensaje": 'Certificado Salud' },
  { 'id_tipo': 7, 'documento': "visa", "nombre_mensaje": 'Certificado visa' },
  { 'id_tipo': 8, 'documento': "discapacidad", "nombre_mensaje": 'Certificado discapacidad' },
  { 'id_tipo': 9, 'documento': "covid", "nombre_mensaje": 'Certificado seguro covid' },
  { 'id_tipo': 10, 'documento': "jubilacion", "nombre_mensaje": 'Certificado jubilacion' }
];

export const DOMINIOS_VALIDOS = [
  "arcoprime.cl",
  "ARCOPRIME.CL",
  "escopec.cl",
  "ESCOPEC.CL",
  "arcoalimentos.cl",
  "ARCOALIMENTOS.CL",
];

export const BACKDROP = '#395c9d5c left top no-repeat';

export const CUSTOM_CLASS = {
  container: 'containerMensajeSimple',
  title: 'titleMensajeSimple',
  text: 'txtLabellInput',
  popup: `popUpSweetCancelarEnviar`,
  confirmButton: 'btnCopec me-4 mt-1',
  cancelButton: 'btnAtras',
}

export const binario = '';

export const customStyles = {
  tableBody: {
    style: {
      borderLeftStyle: 'solid',
      borderLeftWidth: '1px',
      borderLeftColor: defaultThemes.default.divider.default,
      // width: "auto"
      width: 'auto'
    },
  },
  header: {
    style: {
      minHeight: '40px',
    },
  },
  headRow: {
    style: {
      borderTopStyle: 'solid',
      borderTopWidth: '1px',
      // width: 'auto',
      borderTopColor: defaultThemes.default.divider.default,
    },
  },
  headCells: {
    style: {
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,

      },
      marginLeft: '2px',
      marginRight: '2px',
      fontSize: '13px'
      // width: 'auto', 
      // display: 'flex',
      // justifyContent: 'center'
    },
  },
  cells: {
    style: {
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
      },
      marginLeft: '2px',
      marginRight: '2px',
      fontSize: '14px!important'
    },
  },
  responsiveWrapper: {
    style: {
      border: `1px solid ${defaultThemes.default.divider.default}`,
    }
  },
};

export const paginationComponentOptions = {
  rowsPerPageText: 'Filas por página',
  rangeSeparatorText: 'de',
  selectAllRowsItem: true,
  selectAllRowsItemText: 'Todos',
};

export const objStyleCol = {
  display: 'flex',
  justifyContent: 'center',
}

export const txtMensajeDatosIngresados = 'Los datos Laborales no pueden ser editados dado que ya existe una integración en curso del cargo seleccionado con SAP. Si requiere editar los cargos debe comenzar un nuevo proceso de ingreso de personal';
export const txtUnProblema = 'Ha ocurrido un problema!';
export const errDigitosAnio = "Indique el año con 4 dígitos";

export const PERSONA_VALORES_INICIALES = obj => {
  return ({
    datosLaborales: {
      fechaIngreso: "",
      cargo: "",
      planHorario: "",
      unidadOrganizativaRolTemporis: "",
      cargoRolTemporis: "",
      tipoContrato: "",
      fechaFinContrato: "",
      divisionPersonal: "",
    },
    datosPersonales: {
      rut: obj.rut,
      id_persona: obj.data.id_persona ? obj.data.id_persona : "",
      nombres: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      fechaNacimiento: "",
      sexo: "",
      estadoCivil: "",
      nivelFormacion: "",
      nacionalidad: "",
      email: "",
      telefonoCelular: "",
    },
    datosPersonaExtranjera: {
      tipoVisa: 0,
      visaTemporariaDesde: "",
      visaTemporariaHasta: "",
    },
    datosDireccion: {
      calle: "",
      numeroCasa: "",
      numeroDepartamento: "",
      region: "",
      comuna: "",
      tipoDireccion: ''
    },
    datosPrevision: {
      jubilado: "",
      previsionSalud: "",
      previsionSocial: "",
      planPactado: "",
      certificadoCovid: "",
      certificadoDiscapacidad: ""
    },
    datosBancarios: {
      formaDePago: "",
      banco: "",
      tipoCuenta: "",
      numeroCuenta: "",
    },
    datosDocumentos: {
      curriculum: null,
      cedula: null,
      antecedentes: null,
      residencia: null,
      afp: null,
      salud: null,
      visa: null,
      discapacidad: null,
      covid: null,
      jubilacion: null,
    },
  });
};

export const MENSAJE_PROCEDER_CONTRATACION = `¿Está seguro que el cargo y el plan de horario seleccionados son correctos?
Si procede con el proceso de contratación los campos anteriores no serán editables`;

export const objNoSuccess = {
  201: 'Se encontró un registro en proceso',
  204: 'No existen datos a disposición',
  401: 'La sesión ha caducado',
  422: 'El tóken no es válido o no existe',
  501: 'Error de conexión con sap',
  500: 'Error de conexión',
  0: 'Error de red',
}

export const const_objPutPost = {
  'persona': '',
  'personaextranjera': '',
  'vacante': '',
  'personaprevision': '',
  'personasalud': '',
  'personadireccion': '',
  'personadatosbancarios': '',
  'documento': '',
}