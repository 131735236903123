import { useState } from "react";
import { obtenerPosicionesLaboralesApi } from "../../../api/contratacion/datosLaboralesApi";
import { cerrarSession, getBusinessDaysAgo } from "../../../utils/funciones";
import { objNoSuccess } from "../../../utils/Constants";
import { txtMensaje } from "../../../utils/Constants";
import { mensajeSimple } from "../../ui/sweetAlerts";
import { useNavigate } from "react-router-dom";
import { get } from "../../../api/apis";
import moment from "moment";

export const useDatosLaborales = () => {
  const [arrDivisionPersonal, setArrDivisionPersonal] = useState([]);
  const [posicionesLaborales, setPosicionesLaborales] = useState([]);
  const [datosLaboralesErr, setErrors] = useState({});
  let errObj = {};

  const navigate = useNavigate();

  const getDivisionPersonal = async () => {
    const resp = await get('divisionpersonal');
    if (objNoSuccess[resp]) {
      mensajeSimple('error', objNoSuccess[resp], 2500);
      setTimeout(() => { cerrarSession(); }, 2000);
      return;
    }
    const arr = localStorage.getItem('cod_division') === 'BP10' ? resp.data : resp.data.filter(el => el.value !== 'BP10');
    setArrDivisionPersonal(arr);
  }

  const getPosicionesLaborales = async (id_divisionPersonal) => {
    if (!id_divisionPersonal) {
      setPosicionesLaborales([]);
      return;
    }
    const resp = await obtenerPosicionesLaboralesApi(id_divisionPersonal);
    if (resp.status === "success") {
      setPosicionesLaborales(resp.data);
    }
    else {
      mensajeSimple('info', txtMensaje, 9000);
      setTimeout(() => navigate("/consulta-rut"), 9000);
    }
  }

  const getNumbersInString = (string) => {
    let tmp = string.split(" ");
    let map = tmp.map((current) => {
      if (!isNaN(parseFloat(current))) {
        return current;
      }
    });

    let numbers = map.filter(function (value) {
      return value !== undefined;
    });
    return numbers[0] || '45';
  }

  const validateLaborales = (obj, idSolicitud = null) => {
    if (idSolicitud) {
      setErrors({});
      return;
    }
    const { fechaIngreso, cargo, planHorario, tipoContrato, tieneTemporis, unidadOrganizativaRolTemporis, divisionPersonal } = obj;
    const fechaLaboralMinima = getBusinessDaysAgo(5);
    const fechaMaximaIngreso = moment().add(1, 'month');
  

    if (!fechaIngreso) { errObj.fechaIngreso = "Requerido"; }

    if (moment(fechaIngreso).format('YYYY-MM-DD') < fechaLaboralMinima.format('YYYY-MM-DD')) {
      errObj.fechaIngreso = `No se puede ingresar una fecha con más de 5 días de retraso`;
    } 


    const fechaIngreso_ = new Date(fechaIngreso);
    const fechaMaximaIngreso_ = new Date(fechaMaximaIngreso);

    if (fechaIngreso_ > fechaMaximaIngreso_) {
      errObj.fechaIngreso = `No puede solicitar ingreso de personal con más de 30 días de anticipación`;
    }

    if (!cargo) { errObj.cargo = "Requerido"; }
    if (!planHorario) { errObj.planHorario = "Requerido"; }
    if (!divisionPersonal) { errObj.divisionPersonal = "Requerido"; }

    if (tieneTemporis && !unidadOrganizativaRolTemporis) { errObj.unidadOrganizativaRolTemporis = "Requerido"; }  //este valor es true unidadOrganizativaRolTemporis
    if (planHorario.substr(-2, 2) === '22') {  delete errObj.unidadOrganizativaRolTemporis }

    if (!tipoContrato) { errObj.tipoContrato = "Requerido"; }
    else if (tipoContrato && !fechaIngreso) { errObj.tipoContrato = "Es necesario la fecha de ingreso"; }

    setErrors(errObj);
  }

  return {
    arrDivisionPersonal, getDivisionPersonal,
    posicionesLaborales, getPosicionesLaborales,
    getNumbersInString,
    datosLaboralesErr, validateLaborales
  }
}