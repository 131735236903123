import React from "react";
import { EditarContratacionScreen } from "../components/EditarContratacionScreen";
import FormContratacionScreen from "../components/FormContratacionScreen";
import ListadoVacantesScreen from "../components/ListadoVacantesScreen";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import DetalleVacanteScreen from "../components/DetalleVacanteScreen";
import ConsultarRutScreen from "../components/ConsultarRutScreen";
import VerVacateScreen from "../components/verVacante/VerVacateScreen";
import IngresoIntranet from "../components/IngresoIntranet";
import ListadoVacantesAprobadasScreen from "../components/ListadoVacantesAprobadasScreen ";
import { PestaniasReingreso } from "../components/reingreso/ListadoSolicitudReingreso";
import { PageNotFound } from "../components/errores/PageNotFound/404page";

// // Componentes viejos
import { PorEnviarSAP } from "../components/PorEnviarSAP"; // descomantar para usar el componete viejo
import { ResumenSolicitudes } from "../components/ResumenSolicitudes"; // descomantar para usar el componente viejo
// import { PestaniasFirmaContrato } from "../components/firmaContrato/FirmaContrato";  // descomantar para usar el componente viejo
// import { PestaniasFirmaContrato } from "../components/firmaContrato/PestaniasFirmaContrato";

// // Componentes Nuevos
// // Todas las nuevas vista se estan trabajando en la carpeta 'pages'
import Monitor from '../pages/monitor/Index';
import { NoAutorizado } from "../components/errores/noAutorizado/noAutorizado";
import LogTemporis from '../pages/temporis/LogTemporis';
import DetalleLogScreen from '../pages/temporis/VerDetalleLog';
// import NavbarOffcanvas from "react-bootstrap/esm/NavbarOffcanvas";

const Acceso = ({ elm, acc = [] }) => acc.includes(localStorage.getItem('id_perfil')) ? elm : <NoAutorizado />;

const AppRoute = () => {

  return (
    <div className="containerfluid px-4 p-0">
      <Routes>
        <Route path="/consulta-rut" element={<Acceso elm={<ConsultarRutScreen />} acc={['4']} />} />
        <Route path="/formulario-contratacion/:idPersona" element={<Acceso elm={<FormContratacionScreen />} acc={['4']} />} />
        <Route path="/formulario-contratacion" element={<Acceso elm={<FormContratacionScreen />} acc={['4']} />} />
        <Route path="/resumen-solicitudes" element={<Acceso elm={<ResumenSolicitudes />} acc={['3']} />} />
        <Route path="/envio-sap" element={<Acceso elm={<PorEnviarSAP />} acc={['3']} />} />

        <Route path="/" element={<ListadoVacantesScreen />} />
        <Route path="/listSolicitudAprobadas" element={<ListadoVacantesAprobadasScreen />} />
        <Route path="/listSolicitudReingreso" element={<PestaniasReingreso />} />
        <Route path="detalle-vacante/:idSolicitud" element={<DetalleVacanteScreen />} />
        <Route path="vacante/:idSolicitud" element={<VerVacateScreen />} />
        <Route path="editar-vacante/:idSolicitud" element={<EditarContratacionScreen />} />
        <Route path="ingreso-intranet/:token" element={<IngresoIntranet />} />
        <Route path="monitor/" element={<Monitor />} />
        <Route path="log-temporis/" element={<LogTemporis />} />
        <Route path="detalle-temporis/:id" element={<DetalleLogScreen />} />
        

        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </div>
  );
};

export default AppRoute;
