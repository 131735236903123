import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { objStyleCol, customStyles, paginationComponentOptions } from "../../../utils/Constants";
import { formatRUT, momentFecha } from "../../../utils/funciones";
import { modalHistorial } from "../../ui/sweetAlerts";
import { getUno } from "../../../api/apis";
import Spinner from "../../ui/Spinner";

export const TablaHistorialReingreso = () => {
  const navigate = useNavigate();
  const [arrayTabla, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [pageTable, setPageTable] = useState(1);
  const dispatch = useDispatch()

  useEffect(() => { fetchUsers(pageTable) }, []);

  const fetchUsers = async (page) => {
    setLoading(true);
    const resp = await getUno('historialreingreso', { page: page, total_page: perPage }, {});

    setLoading(false);
    if (resp.data.status === 'success') {
      setData(resp.data.data);
      setTotalRows(resp.data.paginacion.total);
    }
  }
  const handlePageChange = page => { setPageTable(page) }
  const handlePerRowsChange = async (newPerPage) => { setPerPage(newPerPage) }

  useEffect(() => { fetchUsers(pageTable, perPage) }, [pageTable, perPage])

  const levantarHistorial = async (el) => {
    const resp = await getUno('historialreingresosolicitud', { idSolicitud: el }, null);
    if (resp?.status === 200) { modalHistorial('Historial de reingreso', resp.data.data) }
  }

  const columns = [
    {
      name: 'N° Personal SAP',
      selector: row => row.reeo_codigo_sap !== null ? row.reeo_codigo_sap : '---',
      style: objStyleCol,
      width: "110px",
    },
    {
      name: 'Nombre',
      sortable: false,
      selector: row => row.nombre,
      wrap: true,
      width: "400px",
    },
    {
      name: 'Rut',
      selector: row => formatRUT(row.identificacion),
      style: objStyleCol,
      width: "105px",
    },
    {
      name: 'Fecha Ingreso',
      selector: row => momentFecha(row.fecha_ingreso.split(' ')[0]),
      style: objStyleCol,
      width: "110px",
    },
    {
      name: 'Estado actual',
      sortable: false,
      selector: row => row.id_solicitud_estado === 16 ? `${row.estado_actual} por ${row.responsable}` : row.estado_actual,
      wrap: true,
      minWidth: "200px",
    },
    {
      name: 'Ver',
      cell: row =>
        <button
          className="btn btnAccion rounded-3 btn-primary btn-sm"
          data-bs-toggle="tooltip"
          title="Ver solicitud"
          onClick={() => levantarHistorial(row.id)}><FontAwesomeIcon icon={faEye} /></button>,
      wrap: true,
      width: "60px",
      style: objStyleCol
    },
  ];

  return (
    <div className="row">
      <div className="col-sm-12 ">
        <div className="card cardPestania">
          {
            loading
              ? <Spinner />
              :
              <div className="card-body contenedorTabla">
                {arrayTabla.length > 0
                  ?
                  (
                    <DataTable
                      title={false}
                      responsive={true}
                      columns={columns}
                      data={arrayTabla}
                      customStyles={customStyles}
                      progressPending={loading}
                      pagination
                      paginationServer
                      paginationDefaultPage={pageTable}
                      paginationPerPage={perPage}
                      paginationTotalRows={totalRows}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      paginationComponentOptions={paginationComponentOptions}
                      dense
                    />
                  )
                  :
                  <p className='h5 text-center my-5 titleMensajeSimple'>No se encontraron registros</p>
                }
              </div>

          }
        </div>
      </div>
    </div>
  );
};