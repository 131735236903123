export const InputBusqueda = ({ ejecutar }) => {
  return (
    <div className="input-group" style={{ width: '250px', height: '20px' }}>
      <input
        type="text"
        className="form-control"
        placeholder="Búsqueda"
        aria-describedby="button-addon2"
        style={{ height: '30px', fontSize: '14px' }}
        onChange={(e) => { ejecutar(e.target.value) }}
      />
    </div>
  )
}

export const InputBusquedaMonitor = ({ ejecutar, searchDisabled }) => {
  return (
    <div className="input-group" style={{ width: '250px', height: '20px' }}>
      <input
        type="text"
        className="form-control"
        placeholder="Búsqueda"
        aria-describedby="button-addon2"
        style={{ height: '30px', fontSize: '14px' }}
        onChange={(e) => { ejecutar(e.target.value) }}
        disabled={searchDisabled}
      />
    </div>
  )
}